/**
 * web接口配置
 */

import url from "../modules/url";

const rnd = Math.floor(Math.random()*99+1)
const rnd2 = Math.floor(Math.random()*99+1)

interface configitem{
  /** 老资讯接口 */
  old_zixun_api:string,
  /** 新资讯接口 qiuyihan */
  new_zixun_api: string,
  /** 公告 duanwenbo */
  new_notice_api:string
  /** 行情 mawenbao */
  quote_api:string,
  /** 行情推送接口 */
  quote_push_api:string,
  /** 行情历史数据接口 */
  quote_history_api:string,  
  /** 行情历史数据推送接口 */
  quote_history_push_api:string,
  /** 期货接口 liyong */
  qihuo_api:string,
  /** 期货推送接口 liyong */
  qihuo_sse_api:string,
  /** 期货静态接口 liyong */
  qihuo_static:string,
  /** 快讯 */
  kuaixun_api:string,
  /** 老的数据接口 */
  old_datacenter: string,
  /** 静态行情图-分时图 zhangheng */
  chart_time: string,
  /** 静态行情图-延迟分时图 zhangheng */
  chart_time_delay: string,  
  /** 静态行情图-K线图 zhangheng */
  chart_kline: string,
  /** 静态行情图-K线图 zhangheng */
  chart_kline_delay: string,
  /** 研报 */
  report_api: string,
  /** 路演api WangAn */
  roadshow_api:string,
  /** 数据接口 */
  datainterface:string,
  /** 数据4期 */
  datacenter4: string,
  // /** app接口 */
  // app_api: string,
  /** 基金接口 */
  fund_api: string,
  /** 盘口异动接口 */
  changes_api:string
}

const config:{
  dev:configitem,
  test: configitem,
  test1: configitem,
  prod: configitem,
  getUrl:(name: keyof configitem)=>string
} = {
  dev: <configitem>{
    old_zixun_api: "//cmsdataapi.eastmoney.com/",
    new_zixun_api: "//np-listapi.eastmoney.com/",
    // new_zixun_api: "http://np-listapi.uat.emapd.com/",
    new_notice_api: "//np-anotice-stock.eastmoney.com/",
    quote_api: "//push2.eastmoney.com/",
    quote_push_api: "//" + rnd + ".push2.eastmoney.com/",
    quote_history_api: "//push2his.eastmoney.com/",
    quote_history_push_api: "//" + rnd2 + ".push2his.eastmoney.com/",
    kuaixun_api: "//newsinfo.eastmoney.com/",
    old_datacenter: "//dcfm.eastmoney.com/",
    chart_time: "//webquotepic.eastmoney.com/",
    chart_time_delay: "//delaywebquotepic.eastmoney.com/",
    chart_kline: "//webquoteklinepic.eastmoney.com/",
    chart_kline_delay: "//delaywebquoteklinepic.eastmoney.com/",
    report_api: "//reportapi.eastmoney.com/",
    roadshow_api: "//list.lvb.eastmoney.com/",
    datainterface: "//datainterface.eastmoney.com/",
    datacenter4 : "//datacenter-web.eastmoney.com/",
    // app_api: "//gwapi.eastmoney.com/",
    qihuo_api: "//futsseapi.eastmoney.com/",
    qihuo_sse_api: "//" + rnd2 + ".futsseapi.eastmoney.com/",
    qihuo_static: "//futsse-static.eastmoney.com/",
    fund_api: "//fundwebapi.eastmoney.com/",
    changes_api: "//push2ex.eastmoney.com/"
  },
  test: <configitem>{
    old_zixun_api: "//cmsdataapi.eastmoney.com/",
    new_zixun_api: "//np-listapi.eastmoney.com/",
    // new_zixun_api: "http://np-listapi.uat.emapd.com/",
    new_notice_api: "//np-anotice-stock-test.eastmoney.com/",
    quote_api: "//push2test.eastmoney.com/",
    quote_push_api: "//push2test.eastmoney.com/",
    quote_history_api: "//push2test.eastmoney.com/",
    quote_history_push_api: "//push2test.eastmoney.com/",
    kuaixun_api: "//newsinfo.eastmoney.com/",
    old_datacenter: "//dcfm.eastmoney.com/",
    chart_time: "http://61.129.249.32:8870/",
    chart_time_delay: "http://61.129.249.32:8870/",
    chart_kline: "http://61.129.249.32:8870/",
    chart_kline_delay: "http://61.129.249.32:8870/",
    report_api: "//reportapi.eastmoney.com/",
    roadshow_api: "//list-qas.lvb.eastmoney.com/",
    datainterface: "//datainterface.eastmoney.com/",
    datacenter4 : "//testdatacenter.eastmoney.com/",
    // app_api: "//gwapi.eastmoney.com/",
    qihuo_api: "//futssetest.eastmoney.com/",
    qihuo_sse_api: "http://futssetest.eastmoney.com/",
    qihuo_static: "//static.futssetest.eastmoney.com/",
    fund_api: "//fundwebapi.eastmoney.com/",
    changes_api: "//push2ex.eastmoney.com/"
  },
  test1: <configitem>{
    old_zixun_api: "//cmsdataapi.eastmoney.com/",
    new_zixun_api: "//np-listapi.eastmoney.com/",
    // new_zixun_api: "http://np-listapi.uat.emapd.com/",
    new_notice_api: "//np-anotice-stock-test.eastmoney.com/",
    quote_api: "//push2test.eastmoney.com/",
    quote_push_api: "//push2test.eastmoney.com/",
    quote_history_api: "//push2test.eastmoney.com/",
    quote_history_push_api: "//push2test.eastmoney.com/",
    kuaixun_api: "//newsinfo.eastmoney.com/",
    old_datacenter: "//dcfm.eastmoney.com/",
    chart_time: "http://61.129.249.32:8870/",
    chart_time_delay: "http://61.129.249.32:8870/",
    chart_kline: "http://61.129.249.32:8870/",
    chart_kline_delay: "http://61.129.249.32:8870/",
    report_api: "//reportapi.eastmoney.com/",
    roadshow_api: "//list-qas.lvb.eastmoney.com/",
    datainterface: "//datainterface.eastmoney.com/",
    datacenter4 : "//testdatacenter.eastmoney.com/",
    // app_api: "//gwapi.eastmoney.com/",
    qihuo_api: "//futssetest1.eastmoney.com/",
    qihuo_sse_api: "http://futssetest1.eastmoney.com/",
    qihuo_static: "//static.futssetest.eastmoney.com/",
    fund_api: "//fundwebapi.eastmoney.com/",
    changes_api: "//push2ex.eastmoney.com/"
  },  
  prod: <configitem>{
    old_zixun_api: "//cmsdataapi.eastmoney.com/",
    new_zixun_api: "//np-listapi.eastmoney.com/",
    new_notice_api: "//np-anotice-stock.eastmoney.com/",
    quote_api: "//push2.eastmoney.com/",
    quote_push_api: "//" + rnd + ".push2.eastmoney.com/",
    quote_history_api: "//push2his.eastmoney.com/",
    quote_history_push_api: "//" + rnd2+ ".push2his.eastmoney.com/",
    kuaixun_api: "//newsinfo.eastmoney.com/",
    old_datacenter: "//dcfm.eastmoney.com/",
    chart_time: "//webquotepic.eastmoney.com/",
    chart_time_delay: "//delaywebquotepic.eastmoney.com/",
    chart_kline: "//webquoteklinepic.eastmoney.com/",
    chart_kline_delay: "//delaywebquoteklinepic.eastmoney.com/",
    report_api: "//reportapi.eastmoney.com/",
    roadshow_api: "//list.lvb.eastmoney.com/",
    datainterface: "//datainterface.eastmoney.com/",
    datacenter4 : "//datacenter-web.eastmoney.com/",
    // app_api: "//gwapi.eastmoney.com/",
    qihuo_api: "//futsseapi.eastmoney.com/",
    qihuo_sse_api: "//" + rnd2 + ".futsseapi.eastmoney.com/",
    qihuo_static: "//futsse-static.eastmoney.com/",
    fund_api: "//fundwebapi.eastmoney.com/",
    changes_api: "//push2ex.eastmoney.com/"
  },
  getUrl(name) {
    let env = url.getQuery('env')

    if (env == "dev") {
      return this.dev[name]
    }
    else if (env == "test") {
      return this.test[name]
    }
    else if (env == "test1") {
      return this.test1[name]
    }
    return this.prod[name]
  }
}



export default config